export const KnownDataClients = () => {
  return (
    <div className="known-section-container doc-section">
      Data clients are academic and commercial resources that use DGIdb data
      (for example by integrating DGIdb interactions into their own tool). The
      current list of DGIdb data clients includes:
      <ul>
        <li>
          <a href="http://biogps.org/#goto=welcome">BioGPS</a>
        </li>
        <li>
          <a href="http://www.cancergd.org">CancerGD</a>
        </li>
        <li>
          <a href="http://cailab.labshare.cn/cancertracer/index.html">
            CancerTracer
          </a>
        </li>
        <li>
          <a href="http://cancrox.gmb.bio.br/view/index.php">CANCROX</a>
        </li>
        <li>
          <a href="http://bioinformatics.cing.ac.cy/codres">CoDReS</a>
        </li>
        <li>
          <a href="http://csgator.ewha.ac.kr">CSgator</a>
        </li>
        <li>
          <a href="https://gemini.readthedocs.io/en/latest/index.html">
            GEMINI - GEnome MINIng
          </a>
        </li>
        <li>
          <a href="http://www.genemed.tech/gene4denovo/home">Gene4Denovo</a>
        </li>
        <li>
          <a href="https://www.genecards.org/">GeneCards</a>
        </li>
        <li>
          <a href="https://github.com/guidmt/GMIEC-shiny/">
            GMIEC - a shiny app
          </a>
        </li>
        <li>
          <a href="http://hemap.uta.fi/hemap/index.html/">Hemap</a>
        </li>
        <li>
          <a href="http://mulinlab.tmu.edu.cn/mtctscan/">mTCTScan</a>
        </li>
        <li>
          <a href="http://ncg.kcl.ac.uk">NCG - Network of Cancer Genes</a>
        </li>
        <li>
          <a href="http://ndexbio.org/#/network/7e5e64ff-f6cf-11ea-99da-0ac135e8bacf">
            NDEx
          </a>
        </li>
        <li>
          <a href="https://github.com/fakedrtom/oncogemini">OncoGemini</a>
        </li>
        <li>
          <a href="http://bioinformaticstools.mayo.edu/research/panda">
            PANDA (Pathway AND Annotation) Explorer
          </a>
        </li>
        <li>
          <a href="https://pct.mdanderson.org/home">
            Personalized Cancer Therapy
          </a>
        </li>
        <li>
          <a href="https://github.com/sdecesco/targetDB">TargetDB</a>
        </li>
        <li>
          <a href="https://sl-biodp.nci.nih.gov/sl_index.php">SL-BioDP</a>
        </li>
        <li>
          <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6206832/">
            SwissMTB
          </a>
        </li>
        <li>
          <a href="https://precious.polito.it/theringdb/home">theRING</a>
        </li>
        <li>
          <a href="http://celllines.tron-mainz.de">
            TCLP - TRON Cell Line Portal
          </a>
        </li>
        <li>
          <a href="http://varcards.biols.ac.cn">VarCards</a>
        </li>
        <li>
          <a href="http://rnd.cgu.edu.tw/vareporter/">VAReporter</a>
        </li>
      </ul>
      <p>
        If your resource is using DGIdb but is missing from this list and you
        would like us to add it, please contact us at{' '}
        <a href="mailto:help@dgidb.org">help@dgidb.org.</a>
      </p>
    </div>
  );
};
